<template>
  <div class="home">
    <div class="title">
      <p class="c-box">
        <i class="el-icon-price-tag" />
        <span @click="goto('/home')">首页</span> > <span class="">运价查询</span>
      </p>
    </div>
    <div class="c-box">
      <div>
        <div class="country">
          <p class="label">目的国家</p>
          <span v-for="item in Country" :key="item.id" :class="{'button':true,'select':forData.stateCode==item.code}" @click="setCode(item.code)">{{ item.name }}</span>
        </div>
        <div class="transport">
          <div>
            <p class="label">运输方式</p>
            <span class="button select">陆运</span>
            <span class="button">海运</span>
          </div>
          <div>
            <p class="label">柜型</p>
            <span class="button select">整柜</span>
            <span class="button">散柜</span>
          </div>
        </div>
      </div>
      <div class="content">
        <el-table
          class="freightRate-table"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            prop="date"
            label="服务名称"
            width="180"
          />
          <el-table-column
            prop="name"
            label="起运港/目的港"
            width="180"
          />
          <el-table-column
            prop="address"
            label="开船日期"
          />
          <el-table-column
            prop="address"
            label="截单日期"
          />
          <el-table-column
            prop="address"
            label="航程"
          />
          <el-table-column
            prop="address"
            label="尺寸"
          />
          <el-table-column
            prop="address"
            label="金额"
          />
          <el-table-column
            prop="address"
            label="单位"
          />
          <el-table-column
            prop="address"
            label="其他费用"
          />
          <el-table-column
            prop="address"
            label="操作"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { findCountry } from '@/api/select.js';
export default {
  name: 'Home',
  props: {

  },
  data: function() {
    return {
      Country: [{ 'id': 1, 'name': '中国', 'code': 'CN', 'geo': '亚洲', 'status': '1' }, { 'id': 2, 'name': '美国', 'code': 'US', 'geo': '北美洲', 'status': '1' }, { 'id': 3, 'name': '日本', 'code': 'JP', 'geo': '亚洲', 'status': '1' }, { 'id': 4, 'name': '加拿大', 'code': 'CA', 'geo': '北美洲', 'status': '1' }],
      forData: {},
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }]
    };
  },
  computed: {

  },
  watch: {
    forData: function(val) {
      console.log(val);
      return this.forData.stateCode == val;
    }
  },
  created() {
    this.findCountry();
  },
  mounted() {

  },
  methods: {
    setCode(id) {
      this.forData.stateCode = id;
      this.$forceUpdate();
    },
    findCountry() { // 查询国家
      if (this.$store.state.country.length) {
        this.Country = this.$store.state.country;
      } else {
        findCountry().then(result => {
          if (result.status == 200) {
            const data = result.data;
            this.Country = data.data;
            this.forData.stateCode = data.data[0].code;
          }
        }).catch(error => {
          console.log(error);
        });
      }
    },
    goto(route) {
      this.$router.push({ path: route });
    }
  }
};

</script>

<style scoped>
.title{height:30px;line-height:30px;background:#F0F0F0;margin:0;font-size:12px;color:#999999}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title span:nth-child(3){color:#333}
.country{margin-top:50px;}
.button{min-width: 80px;cursor:pointer;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;}
.label{font-size:12px;display:inline-block;margin-right:20px}
.select{background:#FC6C3F;color:#fff;border-color:#FC6C3F !important;}
.transport{overflow:hidden;margin-top:44px}
.transport>div{width:50%;float:left;}
.content{margin-top:44px}
</style>
